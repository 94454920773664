import { UserData, UserFlag } from "@/types";
import { Currency } from "@/services/repositories/currency";
import { AccountType } from "./internalUserTypes";
import { Entity } from "../repositories/Entity";

export type Avatar = string | null;

export class User extends Entity {
  private image: Avatar = null;
  public showPrices: boolean;
  public data: UserData;
  private token: string;

  constructor(public id: number, private email: string, private type: AccountType, public name: string) {
    super(id);
  }

  public getEmail(): string {
    return this.email;
  }

  public setImage(image: Avatar): void {
    if (image === "") {
      this.image = null;
    } else {
      this.image = image;
    }
  }

  public getImage(): Avatar {
    return this.image;
  }

  public setShowPrices(show: boolean) {
    this.showPrices = show;
  }

  public isGoogleAccount(): boolean {
    return this.type === AccountType.google;
  }

  public isFacebookAccount(): boolean {
    return this.type === AccountType.facebook;
  }

  public isOurAccount(): boolean {
    return this.type === AccountType.common;
  }

  public hasVisiblePrices(): boolean {
    return this.showPrices;
  }

  public async getCurrency(): Promise<Currency> {
    return this.data.displayCurrency;
  }

  public setToken(token: string): void {
    this.token = token;
  }

  public getToken(): string {
    return this.token;
  }

  public hasFlag(flag: UserFlag): boolean {
    return this.data.flags.has(flag);
  }

  public addFlag(flag: UserFlag): void {
    this.data.flags.add(flag);
  }

  public removeFlag(flag: UserFlag): void {
    this.data.flags.delete(flag);
  }
}
