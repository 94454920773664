import { BuildMode } from "@/types";
import * as Sentry from "@sentry/vue";
import { App } from "vue";

export const setupSentry = (app: App): void => {
  const mode = import.meta.env.MODE;
  if (mode !== BuildMode.production) {
    return;
  }

  const version = import.meta.env.VITE_APP_VERSION;

  Sentry.init({
    app,
    dsn: "https://8e18f85fac52bc541eaee9d6d8e96272@o4507141709496320.ingest.de.sentry.io/4507146124132432",
    integrations: [
      Sentry.replayIntegration(),
      Sentry.breadcrumbsIntegration({
        console: true,
        dom: true,
        fetch: true,
        history: true,
        xhr: true,
      }),
      Sentry.captureConsoleIntegration(),
    ],
    release: version,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};
