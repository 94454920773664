import { translate } from "@/services/translation";
import { CatalogueTableColumn, CategoryId } from "@/types";

/**
 * This will be replaced by API endpoint in the future.
 */
export function getTableColumns(categoryId: number): CatalogueTableColumn[] {
  return columns.get(categoryId) ?? [];
}

const columns = new Map<number, CatalogueTableColumn[]>();

columns.set(CategoryId.vinyl, [
  { label: t("vinyls.name"), key: "catNumber" },
  { label: t("vinyls.interpret"), key: "interpret" },
  { label: t("vinyls.year"), key: "year" },
]);

columns.set(CategoryId.watch, [
  { label: t("watches.reference"), key: "reference", align: "center" },
  { label: t("watches.brand"), key: "brand", align: "center" },
  { label: t("watches.model"), key: "model", align: "center" },
]);

columns.set(CategoryId.legoMinifigs, [
  { label: t("legoMinifigs.id"), key: "number", align: "center" },
  { label: t("legoMinifigs.price"), key: "price", align: "right" },
  { label: t("legoMinifigs.piecesCount"), key: "pieces", align: "center" },
]);

columns.set(CategoryId.lego, [
  { label: t("legoSets.id"), key: "number" },
  { label: t("legoSets.price"), key: "price", align: "right" },
  { label: t("legoSets.year"), key: "year", align: "center" },
]);

columns.set(CategoryId.boardgame, [
  { label: t("boardGames.developer"), key: "developer" },
  { label: t("boardGames.language"), key: "Language" }, // Intentionally uppercase "L" -> API needs it
  { label: t("boardGames.year"), key: "year", align: "center" },
]);

// Helper translation function DRY
function t(path: string): string {
  return translate("views.catalogue.table." + path);
}
