import { Layout, OnlyPublicProperties } from "@/types";
import { translate } from "@/services/translation";
import { RouteLocationRaw, RouteRecordRaw } from "vue-router";
import { AppRouteName } from "./appRoutes";
import { createUrlParams, PublicCollectionItemsFilter } from "@/services/repositories/publicCollectionItems";
import urlSlug from "url-slug";
import { Category } from "@/services/repositories/collection/categories";
import { CollectorsFilter } from "@/services/repositories/collectors";

export enum PublicRouteName {
  profile = "publicProfile",
  publicHomepage = "homepage",
  publicItemDetail = "publicItemCard",
  about = "about",
  gdpr = "gdpr",
  magazine = "articlesList",
  article = "article",
  contacts = "contacts",
  legalInformation = "legalInformation",
  books = "booksDownload",
  publicItemsList = "publicItemsList",
  collectors = "collectors",
}

export const publicRoutes: RouteRecordRaw[] = [
  /**
   * Public simple views
   */

  {
    name: PublicRouteName.publicHomepage,
    path: "",
    components: { mainContent: () => import("@/views/public/HomepagePublic.vue") },
    meta: {
      public: true,
      title: translate("views.publicHomepage.title"),
      layout: { name: Layout.public },
      redirectWhenLoggedIn: { name: AppRouteName.dashboard },
    },
  },
  {
    path: "lego",
    components: { mainContent: () => import("@/views/public/LandingPageLego.vue") },
    meta: {
      public: true,
      title: translate("views.landingPages.lego.title"),
      layout: { name: Layout.public },
    },
  },
  {
    path: "watches",
    components: { mainContent: () => import("@/views/public/LandingPageWatch.vue") },
    meta: {
      public: true,
      title: translate("views.landingPages.watches.title"),
      layout: { name: Layout.public },
    },
  },
  {
    name: PublicRouteName.gdpr,
    path: "privacy-policy",
    components: { mainContent: () => import("@/views/TermsAndCondition.vue") },
    meta: {
      public: true,
      title: translate("views.termsAndConditions.heading"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
  {
    name: PublicRouteName.legalInformation,
    path: "legal-information",
    components: { mainContent: () => import("@/views/public/LegalInformation.vue") },
    meta: {
      public: true,
      title: translate("views.legalInformation.heading"),
      layout: { name: Layout.public },
    },
  },
  {
    name: PublicRouteName.contacts,
    path: "contacts",
    components: { mainContent: () => import("@/views/ContactPage.vue") },
    meta: {
      public: true,
      title: translate("views.contacts.title"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
  {
    name: PublicRouteName.about,
    path: "about-us",
    components: { mainContent: () => import("@/views/AboutUs.vue") },
    meta: {
      public: true,
      title: translate("views.about.title"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
  {
    name: PublicRouteName.books,
    path: "guides",
    components: { mainContent: () => import("@/views/BooksDownload.vue") },
    meta: {
      public: true,
      title: translate("views.books.title"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },

  /**
   * Magazine
   */
  {
    path: "magazine",
    children: [
      {
        name: PublicRouteName.article,
        path: ":id(\\d+)-:url",
        components: { mainContent: () => import("@/views/ArticleDetail.vue") },
        meta: {
          public: true,
          title: translate("views.magazine.titleNoCategory"),
          layout: { name: Layout.public },
          layoutLoggedIn: { name: Layout.app },
        },
      },
      {
        name: PublicRouteName.magazine,
        path: ":category?",
        components: { mainContent: () => import("@/views/ArticlesList.vue") },
        meta: {
          public: true,
          title: translate("views.magazine.titleNoCategory"),
          layout: { name: Layout.public },
          layoutLoggedIn: { name: Layout.app },
          breadcrumbs: [{ text: translate("views.magazine.breadcrumb") }],
        },
      },
    ],
  },

  /**
   * Profiles
   */
  {
    name: PublicRouteName.publicItemDetail,
    path: "collector/:nickname/:slug/:itemId",
    components: { mainContent: () => import("@/views/public/PublicItemDetail.vue") },
    meta: {
      public: true,
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
  {
    name: PublicRouteName.profile,
    path: "collector/:nickname",
    components: { mainContent: () => import("@/views/public/ProfilePublic.vue") },
    meta: {
      public: true,
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },

  /**
   * Public items listing
   */
  {
    name: PublicRouteName.publicItemsList,
    path: "collectors-items",
    components: { mainContent: () => import("@/views/PublicItems.vue") },
    meta: {
      public: true,
      title: translate("views.publicItemsList.title"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
      breadcrumbs: [{ text: translate("views.publicItemsList.title") }],
    },
  },

  /**
   * Collectors list
   */
  {
    name: PublicRouteName.collectors,
    path: "collectors",
    components: { mainContent: () => import("@/views/Collectors.vue") },
    meta: {
      public: true,
      title: translate("views.collectors.title"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
      breadcrumbs: [{ text: translate("views.collectors.heading") }],
    },
  },
];

export const createProfileLink = (nickname: string, filter?: OnlyPublicProperties<PublicCollectionItemsFilter>): RouteLocationRaw => {
  return {
    name: PublicRouteName.profile,
    params: {
      nickname: nickname,
    },
    query: filter ? createUrlParams(filter) : undefined,
  };
};

export const createHomepageLink = (): RouteLocationRaw => ({ name: PublicRouteName.publicHomepage });

export const createPublicItemDetailLink = (itemId: number, itemName: string, collectorNickname: string): RouteLocationRaw => ({
  name: PublicRouteName.publicItemDetail,
  params: {
    nickname: urlSlug(collectorNickname),
    itemId: itemId,
    slug: urlSlug(itemName) || "-",
  },
});

export const createAboutLink = (): RouteLocationRaw => ({ name: PublicRouteName.about });

export const createGdprLink = (): RouteLocationRaw => ({ name: PublicRouteName.gdpr });

export const createArticleLink = (id: number, url: string): RouteLocationRaw => ({
  name: PublicRouteName.article,
  params: { id, url },
});

export const createMagazineLink = (category?: Category): RouteLocationRaw => ({
  name: PublicRouteName.magazine,
  params: {
    category: category ? category.url : undefined,
  },
});

export const createContactsLink = (): RouteLocationRaw => ({ name: PublicRouteName.contacts });

export const createLegalInformationsLink = (): RouteLocationRaw => ({ name: PublicRouteName.legalInformation });

export const createPublicItemsListLink = (filter?: OnlyPublicProperties<PublicCollectionItemsFilter>): RouteLocationRaw => {
  if (filter) {
    return {
      name: PublicRouteName.publicItemsList,
      query: createUrlParams(filter),
    };
  } else {
    return { name: PublicRouteName.publicItemsList };
  }
};

export const createCollectorsLink = (filter?: CollectorsFilter.Filter): RouteLocationRaw => ({
  name: PublicRouteName.collectors,
  query: filter?.createUrlParams(),
});
