import { RouteLocationNormalized } from "vue-router";
import { useBreadcrumbsStore } from "@/store";
import { MetaTags } from "@/services/metaTags";

export const isPublic = (route: RouteLocationNormalized): boolean => route.meta?.public === true;

export const updatePageTitle = (route: RouteLocationNormalized): void => {
  const title = route.meta.title;
  if (title !== undefined) {
    MetaTags.setTitle(route.meta.title);
  }
};

export function updateBreadcrumbs(route: RouteLocationNormalized): void {
  const breadcrumbs = route.meta.breadcrumbs;
  if (breadcrumbs) {
    const breadcrumbsStore = useBreadcrumbsStore();
    if (breadcrumbs.length > 0) {
      breadcrumbsStore.set(breadcrumbs);
    } else {
      breadcrumbsStore.clear();
    }
  }
}
