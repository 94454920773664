export default {
  collection: {
    makeItemPublicDialog: {
      header: "Zveřejnění předmětu",
      text: "Změnou stavu zveřejníš předmět ve svém profilu <strong>bez tvých soukromých parametrů</strong>. Zde si můžeš přidat poznámku či požadovanou cenu k prodeji a profil se sbírkou sdílet.",
      cancel: "Ponechat",
      confirm: "Zveřejnit",
      dontShowNextTime: "Příště nezobrazovat",
      confirmMessage: "Předmět zveřejněn.",
    },
    makeItemPrivateDialog: {
      header: "Odzveřejnění předmětu",
      text: "Tvůj předmět již nebude zveřejněný na tvém profilu. Najdeš ho ve své sbírce.",
      cancel: "Ponechat",
      confirm: "Odzveřejnit",
      dontShowNextTime: "příště nezobrazovat",
      confirmMessage: "Předmět odzveřejněn.",
    },
    itemList: {
      owner: "Sběratel",
      pieces: "Množství",
      piecesUnit: "ks",
      offeringFor: "Nabízím za",
      noNote: "Bez poznámky",
      detailButton: "Detail předmětu",
      visibilityLabel: "Zobrazení předmětu",
      publishDate: "Vystaveno",
    },
    itemsTable: {
      note: {
        label: "Poznámka",
        empty: "Prázdná poznámka",
      },
      delete: "Smazat",
      tags: {
        nothingSelectedLabel: "# Všechny tagy",
        somethingSelectedLabel: "# Počet vybraných: ",
        noOptionsAvailableLabel: "Žádné dostupné tagy.",
        searchInputPlaceholder: "Hledat tag",
      },
    },
    itemRemoveDialog: {
      title: "Odstranění předmětu",
      message: 'Opravdu chcete odstranit "{ itemName }"?',
      ok: "Předmět byl odstraněn.",
      errors: {
        notFound: "Předmět nenalezen.",
        notAllowed: "Nemáš oprávnění předmět smazat.",
        general: "Předmět se nepodařilo smazat.",
      },
    },
    stats: {
      loading: "načítám...",
      labelValue: "Hodnota sbírky",
      labelItemsCount: "Počet předmětů",
    },
  },
};
