import { CollectionItem } from "@/services/repositories/collection/items";
import { createUrlParams, PublicCollectionItem, PublicCollectionItemsFilter } from "@/services/repositories/publicCollectionItems";
import { translate } from "@/services/translation";
import { Layout } from "@/types";
import { RouteLocationRaw } from "vue-router";
import urlSlug from "url-slug";
import { Category } from "@/services/repositories/collection/categories";
import { CatalogueItem, CatalogueSearchItem } from "@/services/repositories/database";
import { Filter as EbayFilter } from "@/services/ebay";
import { ChatUrlFlags } from "@/services/chat";

export enum AppRouteName {
  myItemDetail = "myCollectionItemDetail",
  collectionCategory = "collectionCategory",
  myCollection = "collectionSummary",
  catalogue = "catalogueStart",
  catalogueItem = "catalogueItem",
  catalogueCategory = "catalogueCategory",
  dashboard = "dashboard",
  notifications = "notifications",
  userSettings = "profileSettings",
  myProfile = "myProfile",
  profileInitialization = "profileInitialization",
  articleDraft = "articleDraft",
  ebayListing = "ebay",
  chat = "chat",
  chatConversation = "chatConversation",
  chatEmailNotificationsUnsubscribed = "chatUnsubscribed",
  chatEmailNotificationsUnsubscribeError = "chatUnsubscribeError",
}

export const appRoutes = [
  /**
   * Magazine
   */

  {
    name: AppRouteName.articleDraft,
    path: "magazine/preview/:id(\\d+)-:url/",
    components: { mainContent: () => import("@/views/ArticleDetail.vue") },
    meta: {
      layout: { name: Layout.app },
    },
  },

  /**
   * My collection
   */

  {
    path: "collection",
    children: [
      {
        path: "",
        name: AppRouteName.myCollection,
        components: { mainContent: () => import("@/views/app/myCollection/Overview.vue") },
        meta: {
          title: translate("views.myCollection.overall.title"),
          layout: { name: Layout.app },
          breadcrumbs: [{ text: translate("views.myCollection.overall.breadcrumbs") }],
        },
      },
      {
        name: AppRouteName.collectionCategory,
        path: ":url",
        components: { mainContent: () => import("@/views/app/myCollection/Category.vue") },
        meta: {
          layout: { name: Layout.app },
          breadcrumbs: [{ to: { name: AppRouteName.myCollection }, text: translate("views.myCollection.overall.breadcrumbs") }],
        },
      },
    ],
  },

  /**
   * My item detail
   */

  {
    name: AppRouteName.myItemDetail,
    path: "my-item/:id(\\d+)/:nameSeoSlug?",
    components: { mainContent: () => import("@/views/app/myCollection/ItemDetail.vue") },
    meta: {
      layout: { name: Layout.app },
    },
  },

  /**
   * Dashboard
   */

  {
    name: AppRouteName.dashboard,
    path: "dashboard",
    components: { mainContent: () => import("@/views/app/AppDashboard.vue") },
    meta: {
      title: translate("views.dashboard.title"),
      layout: { name: Layout.app },
      breadcrumbs: [{ text: translate("views.dashboard.breadcrumbs") }],
    },
  },

  /**
   * Database
   */

  {
    path: "database",
    children: [
      {
        path: "",
        name: AppRouteName.catalogue,
        components: { mainContent: () => import("@/views/app/catalogue/Intro.vue") },
        meta: {
          title: translate("views.catalogue.intro.title"),
          breadcrumbs: [{ text: translate("views.catalogue.breadcrumbs") }],
          layout: { name: Layout.app },
        },
      },
      {
        name: AppRouteName.catalogueCategory,
        path: ":categoryUrl",
        components: { mainContent: () => import("@/views/app/catalogue/Category.vue") },
        meta: {
          title: translate("views.catalogue.intro.title"),
          breadcrumbs: [{ text: translate("views.catalogue.intro.title"), to: { name: AppRouteName.catalogue } }],
          layout: { name: Layout.app },
        },
      },
      {
        name: AppRouteName.catalogueItem,
        path: ":categoryUrl/:itemId(\\d+)/:nameSeoSlug?",
        components: { mainContent: () => import("@/views/app/catalogue/ItemDetail.vue") },
        meta: {
          title: translate("views.catalogue.itemDetail.title"),
          breadcrumbs: [{ text: translate("views.catalogue.breadcrumbs"), to: { name: AppRouteName.catalogue } }],
          layout: { name: Layout.app },
        },
      },
    ],
  },

  /**
   * User profile
   */

  {
    name: AppRouteName.userSettings,
    path: "profile-settings",
    components: { mainContent: () => import("@/views/app/UserSettings.vue") },
    meta: {
      title: translate("views.userSettings.title"),
      layout: { name: Layout.app },
      breadcrumbs: [{ text: translate("views.userSettings.breadcrumbs") }],
    },
  },
  {
    name: AppRouteName.myProfile,
    path: "profile",
    components: { mainContent: () => import("@/views/app/ProfileMy.vue") },
    meta: {
      title: translate("views.profile.my.title"),
      layout: { name: Layout.app },
      breadcrumbs: [{ text: translate("views.profile.my.title") }],
      preventScrollToTop: true,
    },
  },

  /**
   * Ebay
   */

  {
    name: AppRouteName.ebayListing,
    path: "price-calculator",
    components: { mainContent: () => import("@/views/app/EbayListing.vue") },
    meta: {
      title: translate("views.ebay.title"),
      layout: { name: Layout.app },
      breadcrumbs: [{ text: translate("views.ebay.breadcrumbs") }],
    },
  },

  /**
   * Chat
   */
  {
    path: "messages/email-unsubscription-successfull",
    name: AppRouteName.chatEmailNotificationsUnsubscribed,
    components: { mainContent: () => import("@/views/ChatEmailNotificationsUnsubscribed.vue") },
    meta: {
      public: true,
      title: translate("views.chat.unsubscribe.title"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
      breadcrumbs: [
        {
          to: { name: AppRouteName.chat },
          text: translate("views.chat.breadcrumbs"),
        },
        {
          text: translate("views.chat.unsubscribe.breadcrumb"),
        },
      ],
    },
  },
  {
    path: "messages/email-unsubscription-failed",
    name: AppRouteName.chatEmailNotificationsUnsubscribeError,
    components: { mainContent: () => import("@/views/ChatEmailNotificationsUnsubscribeError.vue") },
    meta: {
      public: true,
      title: translate("views.chat.unsubscribeError.title"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
      breadcrumbs: [
        {
          to: { name: AppRouteName.chat },
          text: translate("views.chat.breadcrumbs"),
        },
        {
          text: translate("views.chat.unsubscribeError.breadcrumb"),
        },
      ],
    },
  },
  {
    path: "messages",
    meta: {
      title: translate("views.chat.pageTitle"),
      layout: { name: Layout.app },
      breadcrumbs: [{ text: translate("views.chat.breadcrumbs") }],
    },
    children: [
      {
        path: "",
        name: AppRouteName.chat,
        components: { mainContent: () => import("@/views/app/ChatView.vue") },
      },
      {
        path: ":buddyId(\\d+)/:buddyNickname",
        name: AppRouteName.chatConversation,
        components: { mainContent: () => import("@/views/app/ChatView.vue") },
      },
    ],
  },

  /**
   * Others
   */

  {
    name: AppRouteName.notifications,
    path: "notifications",
    components: { mainContent: () => import("@/views/app/NotificationsList.vue") },
    meta: {
      title: translate("views.notifications.title"),
      layout: { name: Layout.app },
      breadcrumbs: [{ text: translate("views.notifications.breadcrumbs") }],
    },
  },
  {
    name: AppRouteName.profileInitialization,
    path: "sign-in/completion",
    components: { mainContent: () => import("@/views/app/ProfileInitialization.vue") },
    meta: {
      title: translate("views.profileInitialization.title"),
      layout: {
        name: Layout.appDialog,
        height: "dynamic",
        width: "small",
        class: "profile-dialog",
      },
    },
  },
];

export const createMyItemDetailLink = (item: CollectionItem | PublicCollectionItem): RouteLocationRaw => ({
  name: AppRouteName.myItemDetail,
  params: {
    id: item.getId(),
    nameSeoSlug: urlSlug(item.name),
  },
});

export const createCollectionCategoryLink = (categoryUrl: string): RouteLocationRaw => ({
  name: AppRouteName.collectionCategory,
  params: {
    url: categoryUrl,
  },
});

export const createMyCollectionLink = (): RouteLocationRaw => ({ name: AppRouteName.myCollection });

export const createCatalogueLink = (): RouteLocationRaw => ({ name: AppRouteName.catalogue });

export const createCatalogueItemLink = (category: Category, item: CatalogueItem | CatalogueSearchItem): RouteLocationRaw => {
  const categoryUrl = category.getUrl();
  const itemId = item.getId();
  const nameSeoSlug = urlSlug(item.getName());
  return {
    name: AppRouteName.catalogueItem,
    params: { categoryUrl, itemId, nameSeoSlug },
  };
};

export const createCatalogueCategoryLink = (category: Category): RouteLocationRaw => ({
  name: AppRouteName.catalogueCategory,
  params: {
    categoryUrl: category.getUrl(),
  },
});

export const createDashboardLink = (): RouteLocationRaw => ({ name: AppRouteName.dashboard });

export const createNotificationsLink = (): RouteLocationRaw => ({ name: AppRouteName.notifications });

export const createUserSettingsLink = (anchor?: string): RouteLocationRaw => ({
  name: AppRouteName.userSettings,
  hash: anchor ? "#" + anchor : undefined,
});

export const createMyProfileLink = (filter?: PublicCollectionItemsFilter): RouteLocationRaw => ({
  name: AppRouteName.myProfile,
  query: filter ? createUrlParams(filter) : undefined,
});

export const createProfileInitializationLink = (): RouteLocationRaw => ({ name: AppRouteName.profileInitialization });

export const createEbayListingLink = (filter?: EbayFilter): RouteLocationRaw => {
  if (filter) {
    return {
      name: AppRouteName.ebayListing,
      query: {
        q: filter.q,
        sort: filter.sort,
        category: filter.category,
      },
    };
  } else {
    return { name: AppRouteName.ebayListing };
  }
};

export const createChatLink = (): RouteLocationRaw => ({ name: AppRouteName.chat });

export const createConversationLink = (buddyId: number, buddyNickname: string, flags?: ChatUrlFlags): RouteLocationRaw => ({
  name: AppRouteName.chatConversation,
  params: { buddyId, buddyNickname },
  query: flags,
});
