export default {
  about: {
    title: "O nás",
    employees: {
      tom: {
        job: "Zakladatel",
        text: `
          <p>Vždycky mě bavilo tvořit nové projekty. Víc než kdy jindy jsem měl chuť udělat něco skutečně pro lidi.
          Něco, co jim bude dělat opravdovou radost, nebo ji alespoň podporovat. A&nbsp;sběratelství tomu bezesporu nahrává.
          Sám sbírám všechno možný, začal jsem na gymplu sběratelskýma kartičkama a&nbsp;postupně objevoval další oblasti.
          Věřím, že&nbsp;fyzické předměty mají své místo i&nbsp;v&nbsp;dnešní digitální době. A&nbsp;tak teď koukám celý dny do&nbsp;LEGA, hodinek atd. se super partou lidí... koho by to netěšilo? 🙂</p>
        `,
      },
      lukas: {
        job: "Vývoj",
        text: `
          <p>Už jako malý jsem si našel oblibu ve sbírání a&nbsp;vystavování si věcí, které pro mě byly vzácné. Od LEGA® na narozeniny, přes angličáky
          za zkoušky na umělecké škole, až po hokejové kartičky a&nbsp;pogy. S&nbsp;postupem věku, přibývajícími financemi a&nbsp;technickým zázemím se moje sbírka
          rozšířila o&nbsp;staré telefony a&nbsp;staré PC. Je krásné se obklopovat věcmi a&nbsp;lidmi, kteří dělají člověka šťastným. Mým životním smyslem je míti
          pod drobnohledem a&nbsp;pečlivou kontrolou nejen moji sbírku ve skříni, ale i&nbsp;vývoj a&nbsp;technické zázemí našeho projektu.</p>
        `,
      },
      robert: {
        job: "Produkt",
        text: `
          <p>Sběratelství mě provází celý život. Od Lego® stavebnice v útlém dětství, kartiček Magiců, Pokémonů a&nbsp;hokejistů na&nbsp;gymplu až po umění v&nbsp;dospělosti.
          Sbírání je pro mě hrou, která má vždycky pozitivní dopad na moji náladu. Mám ale i&nbsp;jiné vášně. Píši knihy, věnuji se&nbsp;hudbě a&nbsp;přátelům.</p>
        `,
      },
      radim: {
        job: "Design",
        text: `
          <p>Ten pocit, když si rozbalíš zásilku s&nbsp;novými kartičkami, to je prostě droga. Od&nbsp;hokejistů jsem se prokousal ke&nbsp;karetní hře Magic a&nbsp;tato vášeň se mě
          drží už přes dvacet let. Začínám objevovat i&nbsp;svět hodinek, kterým mě nakazili kluci v&nbsp;kanceláři. Když mi Tom představil projekt, věděl jsem,
          že chci být u&nbsp;toho a&nbsp;společně tvořit svět pro všechny takové nadšence a&nbsp;blázny, jako jsme my.
          V&nbsp;rámci projektu mám na&nbsp;starost grafický design, který jsem v&nbsp;Brně studoval.<p>
        `,
      },
      filip: {
        job: "Vývoj",
        text: `
          <p>Kdo by si nepamatoval sbírání hokejových karet na&nbsp;přelomu tisíciletí? Tehdy jsme sedávali na&nbsp;obrubníku a&nbsp;měnili tři Hejduky za jednoho Jágra. Chodili jsme za cizíma lidma,
          o&nbsp;kterých jsme se doslechli, že mají album, a&nbsp;snažili jsme se od nich získat další Jágry. To byl sběratelský punk!</p>
          <p>Láska k&nbsp;hokejovým kartičkám mi zůstala dodnes a&nbsp;já mám radost, že prostřednictvím Colectia mohu sběratelům pomoci se opět potkávat na pomyslném obrubníku (byť jen virtuálním).</p>
          <p>Kromě hokejistů mi však dělá radost i&nbsp;florbal nebo třeba akvaristika.</p>
        `,
      },
      pavla: {
        job: "Sociální sítě",
        text: `
          <p>Upřímně? Jsem tak trochu sběratel amatér. Když jsem byla malá, vedlo u&nbsp;mě na plné čáře Lego® (u&nbsp;koho taky ne) a&nbsp;milovala jsem Čtyřlístek.
          Teď jsem k&nbsp;Legu přidala sneakers, streetwear, přicházím na&nbsp;chuť Pokémonům a&nbsp;koukám po&nbsp;hodinkách. Což může znít jako zvláštní kombinace,
          ale právě proto mě práce v&nbsp;Colectiu baví - rozšiřuje mi moje sběratelské obzory.</p>
        `,
      },
    },
    heading: "O nás",
    textIntro1: "Věříme, že v&nbsp;životě je také potřeba si&nbsp;dělat radost. Rozhodli jsme se&nbsp;vytvořit přátelské místo, kde můžeme sdílet svoji vášeň. Jsme parta nadšenců, kteří dělají, co je baví.",
    textIntro2: "Spuštěním tohoto webu jsme napsali první stranu našeho příběhu. Našim cílem je&nbsp;vybudovat platformu, kde budou lidé zapomínat na své každodenní starosti, kam se budou rádi vracet a&nbsp;kde najdou další parťáky se stejným nadšením. Vytvoříme místo, kde se budou vzájemně podporovat a&nbsp;radit si. Nabídneme nástroje a&nbsp;služby, které lidem pomůžou se vším, co budou při své cestě potřebovat.",
    email: "e-mail:",
    whatMakeMeHappy: "Co mi dělá radost:",
  },
};
