import { Layout } from "@/types";
import { RouteRecordRaw } from "vue-router";
import { AppRouteName } from "./appRoutes";
import { RouteLocationRaw } from "vue-router";
import { translate } from "@/services/translation";

export enum LockingStateRouteName {
  registration = "registrationNew",
  registrationEmailVerified = "registrationEmailVerified",
  login = "login",
  passwordRestore = "forgottenPassword",
}

export const lockingStateRoutes: RouteRecordRaw[] = [
  /**
   * Login
   */

  {
    path: "login",
    components: { mainContent: () => import("@/views/lockingStates/Login.vue") },
    name: LockingStateRouteName.login,
    meta: {
      public: true,
      title: translate("views.auth.login.title"),
      layout: { name: Layout.lockingState },
      redirectWhenLoggedIn: AppRouteName.dashboard,
    },
  },

  /**
   * Registration
   */

  {
    path: "sign-in",
    children: [
      {
        path: "",
        name: LockingStateRouteName.registration,
        components: { mainContent: () => import("@/views/lockingStates/Registration.vue") },
        meta: {
          public: true,
          title: translate("views.auth.registration.title"),
          layout: { name: Layout.lockingState },
          redirectWhenLoggedIn: AppRouteName.dashboard,
        },
      },
      {
        name: LockingStateRouteName.registrationEmailVerified,
        path: "email-verification/:hash",
        components: { mainContent: () => import("@/views/lockingStates/EmailVerified.vue") },
        meta: {
          public: true,
          title: translate("views.auth.emailVerification.title"),
          layout: { name: Layout.lockingState },
          redirectWhenLoggedIn: AppRouteName.dashboard,
        },
      },
    ],
  },

  /**
   * Password restore
   */

  {
    path: "password-reset",
    children: [
      {
        path: "",
        name: LockingStateRouteName.passwordRestore,
        components: { mainContent: () => import("@/views/lockingStates/PasswordRestoreRequest.vue") },
        meta: {
          public: true,
          title: translate("views.auth.passwordRestore.title"),
          layout: { name: Layout.lockingState },
          redirectWhenLoggedIn: AppRouteName.dashboard,
        },
      },
    ],
  },
  {
    path: "password-reset/:hash",
    components: { mainContent: () => import("@/views/lockingStates/PasswordRestore.vue") },
    meta: {
      public: true,
      title: translate("views.auth.passwordRestore.title"),
      layout: { name: Layout.lockingState },
      redirectWhenLoggedIn: AppRouteName.dashboard,
    },
  },
];

export const createRegistrationLink = (): RouteLocationRaw => ({ name: LockingStateRouteName.registration });

export const createLoginLink = (redirectAfterLogin?: string): RouteLocationRaw => ({
  name: LockingStateRouteName.login,
  query: {
    redirect_url: redirectAfterLogin ?? undefined,
  },
});

export const createPasswordRestoreLink = (): RouteLocationRaw => ({ name: LockingStateRouteName.passwordRestore });
