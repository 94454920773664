export default {
  myCollection: {
    overall: {
      title: "Moje sbírka",
      breadcrumbs: "Moje sbírka",
      heading: "Moje sbírka",
      loadingCategories: "Načítám kategorie…",
    },
    category: {
      title: "Moje sbírka: { categoryName }",
      headingText: "Detail Sbírky",
      headingChart: "Vývoj hodnoty sbírky",
      collectionValue: "Hodnota sbírky",
      collectionAcquirePrice: "Pořizovací cena sbírky",
      collectionGain: "Aktuální zisk",
      collectionItemsCount: "Počet předmětů",
      loading: "Načítám detaily sbírky…",
      chart: {
        priceCurrent: "Odhadovaná cena",
        priceAcquire: "Pořizovací cena",
      },
    },
    grid: {
      heading: "Seznam předmětů",
      empty: {
        hi: "Ahoj",
        text: "Zatím nemáš ve své sbírce žádný předmět",
        cta: "Přidat předmět",
      },
    },
  },
};
