export default {
  books: {
    title: "Příručky",
    heading: "Příručky ke stažení",
    textSubheading: "Postupně pro vás chystáme užitečné příručky, které se zaměřují na investice do jednotlivých oblastí. Pokud jste začínající sběratel nebo se jen chcete dozvědět užitečné informace, najít kvalitní zdroje, příručky jsou pro vás zdarma ke stažení. Na nic nečekejte. :)",
    download: "Stáhnout",
    books: {
      art: "Jak začít s investováním do umění",
      shoes: "Jak začít s investováním do tenisek",
      lego: "Jak začít s investováním do LEGA",
      watch: "Jak začít s investováním do hodinek",
      alcohol: "Jak začít s investovaním do alkoholu",
    },
  },
};
