export default {
  itemDetail: {
    loading: "Načítám předmět...",
    tabs: {
      generalParams: "Parametry",
      publicParams: "Veřejné údaje",
      privateParams: "Soukromé údaje",
    },
    itemNotPublicMsg: "Pro úpravu a zveřejnění těchto údajů musí být předmět ve&nbsp;stavu&nbsp;<strong>veřejný</strong>.",
    makeItemPublicBtn: "Zveřejnit předmět",
    params: {
      nameEditBtnTitle: "Změnit název",
      privateNoteLabel: "Soukromá poznámka",
      publicNoteLabel: "Veřejná poznámka",
      noteLabel: "Poznámka",
      isItemPublicLabel: "Veřejný předmět",
      isItemPublicTooltip: "Pokud nastavíš předmět jako veřejný, ostatní sběratelé jej uvidí ve tvém profilu. Pokud ho nastavíš jako neveřejný, uvidíš ho jen ty ve své sbírce.",
      includeIntoStatsLabel: "Statistiky",
      includeIntoStatsTooltip: "Započítat předmět do statistik tvé sbírky. Hodnota předmětu a její vývoj se bude projevovat na grafech a v ostatních statistikách.",
      acquireSource: "Odkud získáno",
      whereStored: "Kde skladuji",
      publicOfferedPrice: "Nabízím za",
      negotiablePrice: "Cena dohodou",
      publicPieces: "Kusy",
      acquireDate: "Datum pořízení",
      acquirePrice: "Pořizovací cena",
      currentPrice: "Orientační cena",
      tags: "Tagy",
      tagsHelp: "Doplň si k předmětu tagy, podle kterých můžeš ve sbírce filtrovat předměty.",
      tagsShowAll: "zobrazit vše",
      tagsShowLess: "zobrazit méně",
      tagRemoved: "Tag { tag } odstraněn.",
      tagsSave: "Uložit",
      pieces: "Počet kusů",
      owner: "Sběratel",
      publishDate: "Datum zveřejnění",
      priceAdjustment: "Upravená cena",
      priceAdjustmentTooltip: "Je tvůj předmět poškozený a ztratil na hodnotě nebo je něčím prémiový a naopak má hodnotu vyšší? Uprav si podle toho cenu.",
      legoSet: {
        theme: "Téma",
        number: "Číslo",
        pieces: "Počet kostiček",
        year: "Rok vydání",
        size: "Rozměry krabice",
        weight: "Hmotnost",
      },
      legoMinifig: {
        number: "Číslo",
        pieces: "Počet kostiček",
      },
      saveInProgress: "Ukládám",
      saveBtn: "Uložit",
      saved: "Uloženo",
      saveFailed: "Nepodařilo se uložit.",
    },
    errors: {
      noteTooLong: "Poznámka může být nejvýše { maxLength } znaků dlouhá.",
      whereStoredTooLong: '"Kde skladuji" může být nejvýše { maxLength } znaků dlouhé.',
      whereStoredTooShort: '"Kde skladuji" musí být alespoň { minLength } znaků dlouhé.',
      acquisitionSourceTooLong: '"Odkud získáno" může být nejvýše { maxLength } znaků dlouhé.',
      acquisitionSourceTooShort: '"Odkud získáno" musí být alespoň { minLength } znaků dlouhé.',
      nameTooLong: "Název nebo ID předmětu nesmí být delší než { maxLength} znaků.",
      nameTooShort: "Název nebo ID předmětu se musí skládat z alespoň { minLength } znaku.",
      itemsListNotFound: "Bohužel, hledané předměty nebyly nalezeny.",
      publicOfferedPriceTooLow: "Příliš nízká hodnota.",
      publicOfferedPriceTooHigh: "Hodnota nesmí být vyšší než { max }.",
      publicPiecesTooLow: "Zadejte minimálně jeden kus",
      publicPiecesTooHigh: "Hodnota nesmí být vyšší než { max }.",
      piecesTooLow: "Zadejte minimálně jeden kus",
      piecesTooHigh: "Hodnota nesmí být vyšší než { max }.",
      acquirePriceTooLow: "Pořizovací cena nesmí být nižší než { min }",
      acquirePriceTooHigh: "Pořizovací cena nesmí být vyšší než { max }.",
      currentPriceTooLow: "Orientační cena nesmí být nižší než { min }",
      currentPriceTooHigh: "Orientační cena nesmí být vyšší než { max }.",
      priceAdjustmentTooLow: "Hodnota nesmí být nižší než { min }",
      priceAdjustmentTooHigh: "Hodnota nesmí být vyšší než { max }.",
      wrongAcquireDate: "Vyplň platné datum.",
      itemLoadError: "Předmět se nepodařilo načíst.",
      tagTooShort: "Vyplň alespoň { minLength } znaky.",
      tagTooLong: "Vyplň nejvíce {  } znaků.",
    },
    messages: {
      itemAddedIntoList: "Zveřejnil jsi předmět.",
      itemRemovedFromList: "Předmět jsi schoval před světem. Vidíš ho už jen ty.",
      tagAdded: "Tag { tag } přidán.",
    },
    actions: {
      sendMessage: "Napsat sběrateli",
      delete: "Smazat předmět",
    },
    chart: {
      heading: "Vývoj orientační ceny",
    },
    gallery: {
      added: "Fotka přidána.",
      removed: "Smazal(a) jsi fotku.",
      coverSet: "Nastaveno jako cover.",
    },
  },
};
