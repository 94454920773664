import { User } from "@/services/user";
import { defineStore } from "pinia";

type UserStoreValue = undefined | User;

type UserStoreState = {
  user: UserStoreValue;
};

export const useUserStore = defineStore("user", {
  state: () =>
    ({
      user: undefined,
    } as UserStoreState),

  actions: {
    get(): UserStoreValue {
      return this.user as UserStoreValue;
    },
    login(user: User) {
      this.user = user;
    },
    logout() {
      this.user = undefined;
    },
  },
});
