import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { App, inject } from "vue";
import { BuildMode } from "@/types";
import { translate } from "@/services/translation";

/** Defined by Google. We have to manage granted/denied for each of this category. */
enum GtmCategory {
  AdStorage = "ad_storage",
  AdUserData = "ad_user_data",
  AdPersonalization = "ad_personalization",
  AnalyticsStorage = "analytics_storage",
  FunctionalityStorage = "functionality_storage",
  PersonalizationStorage = "personalization_storage",
  SecurityStorage = "security_storage",
}

/** Categories in our cookie consent window */
enum OurCookieCategory {
  Neccesary = "neccessary",
  Analytics = "analytics",
  Ads = "ads",
  Personalization = "personalization",
}

const OurCategories2GtmCategoriesMap = new Map<OurCookieCategory, GtmCategory[]>([
  [OurCookieCategory.Neccesary, [GtmCategory.FunctionalityStorage]],
  [OurCookieCategory.Analytics, [GtmCategory.AnalyticsStorage]],
  [OurCookieCategory.Ads, [GtmCategory.AdStorage, GtmCategory.AdPersonalization]],
  [OurCookieCategory.Personalization, [GtmCategory.PersonalizationStorage, GtmCategory.AdUserData, GtmCategory.SecurityStorage]],
]);

enum ConsentApprovalStatus {
  Granted = "granted",
  Denied = "denied",
}

const PluginName = "CookieConsent";

export const CookieConsentPlugin = {
  install: (app: App) => {
    const cc = CookieConsent;
    cc.run(CookieConsentConfig);
    app.provide(PluginName, cc);
  },
};

// Components' composable
export const useCookieConsent = () => {
  const cc = inject(PluginName) as typeof CookieConsent;
  const openSettings = () => cc.showPreferences();
  return { openSettings };
};

function updateGtmConsent(allowedCategories: OurCookieCategory[], rejectedCategories: OurCookieCategory[]) {
  const mode = import.meta.env.MODE;
  if (mode !== BuildMode.production) {
    return;
  }

  if (!window.gtag) {
    throw new Error("DEV: GTM not found!");
  }

  const gtmAllowedCategories = allowedCategories.map((c) => OurCategories2GtmCategoriesMap.get(c)).flatMap((i) => i);
  const gtmRejectedCategories = rejectedCategories.map((c) => OurCategories2GtmCategoriesMap.get(c)).flatMap((i) => i);

  const setup: { [key in GtmCategory]?: ConsentApprovalStatus } = {};
  const gtmCategories = Object.values(GtmCategory);
  gtmCategories.forEach((category) => {
    if (gtmAllowedCategories.includes(category)) {
      setup[category] = ConsentApprovalStatus.Granted;
    } else if (gtmRejectedCategories.includes(category)) {
      setup[category] = ConsentApprovalStatus.Denied;
    } else {
      throw new Error("DEV: Should not happen.");
    }
  });

  const PropertyName = "consent";
  const SetupType = "update";
  window.gtag(PropertyName, SetupType, setup);
}

// Translation helper -> DRY
const t = (namespace: string) => translate("components.cookieConsent." + namespace);

const CookieConsentConfig: CookieConsent.CookieConsentConfig = {
  revision: 1, // Just a current cookie setup version number. Increment if new consent by users is required.
  lazyHtmlGeneration: true,
  autoClearCookies: true,
  guiOptions: {
    consentModal: {
      layout: "box wide",
      position: "bottom center",
      equalWeightButtons: true,
      flipButtons: true,
    },
  },
  categories: {
    [OurCookieCategory.Neccesary]: {
      readOnly: true,
      enabled: true,
    },
    [OurCookieCategory.Analytics]: {},
    [OurCookieCategory.Ads]: {},
    [OurCookieCategory.Personalization]: {},
  },
  language: {
    default: "cs",

    translations: {
      cs: {
        consentModal: {
          title: t("modal.title"),
          description: t("modal.description"),
          acceptAllBtn: t("modal.acceptAllBtn"),
          acceptNecessaryBtn: t("modal.acceptNecessaryBtn"),
          showPreferencesBtn: t("modal.showPreferencesBtn"),
          closeIconLabel: t("modal.closeIconLabel"),
        },
        preferencesModal: {
          title: t("preferencesModal.title"),
          acceptAllBtn: t("preferencesModal.acceptAllBtn"),
          acceptNecessaryBtn: t("preferencesModal.acceptNecessaryBtn"),
          savePreferencesBtn: t("preferencesModal.savePreferencesBtn"),
          sections: [
            {
              title: t("preferencesModal.sections.description.title"),
              description: t("preferencesModal.sections.description.description"),
            },
            {
              title: t("preferencesModal.sections.essentials.title"),
              description: t("preferencesModal.sections.essentials.description"),
              linkedCategory: OurCookieCategory.Neccesary,
            },
            {
              title: t("preferencesModal.sections.analytics.title"),
              description: t("preferencesModal.sections.analytics.description"),
              linkedCategory: OurCookieCategory.Analytics,
            },
            {
              title: t("preferencesModal.sections.ads.title"),
              description: t("preferencesModal.sections.ads.description"),
              linkedCategory: OurCookieCategory.Ads,
            },
            {
              title: t("preferencesModal.sections.personalization.title"),
              description: t("preferencesModal.sections.personalization.description"),
              linkedCategory: OurCookieCategory.Personalization,
            },
          ],
        },
      },
    },
  },
  onChange: () => {
    const allowedCategories = CookieConsent.getUserPreferences().acceptedCategories;
    const rejectedCategories = CookieConsent.getUserPreferences().rejectedCategories;
    updateGtmConsent(allowedCategories as OurCookieCategory[], rejectedCategories as OurCookieCategory[]);
  },
  onConsent: () => {
    const allowedCategories = CookieConsent.getUserPreferences().acceptedCategories;
    const rejectedCategories = CookieConsent.getUserPreferences().rejectedCategories;
    updateGtmConsent(allowedCategories as OurCookieCategory[], rejectedCategories as OurCookieCategory[]);
  },
};
